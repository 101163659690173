<template>
  <div class="container" ref="container">
    <a-button style="margin-top: 25px; margin-left: 20px" @click="goBack">
      返回
    </a-button>
    <div class="wain" v-if="errMsg">
      <a-icon type="info-circle" class="wainIcon" />
      <p>
        <!-- 需完成企业认证才可以进行投保。为了不影响您的投标投保，请先完成企业认证！<br />
        企业认证需在工作日时间（9:00-17:00）内提交。工作日提交当日审核完毕，节假日、非工作日时间内提交的依次顺延审核完毕。 -->
        {{ errMsg }}
      </p>
    </div>
    <div class="main">
      <PlateTitle title="企业认证" />
    </div>
    <div class="form">
      <a-form
        :form="form"
        :label-col="{ span: 10 }"
        :wrapper-col="{ span: 14 }"
        @submit="handleSubmit"
      >
        <div class="form-title">
          —————— <span class="txt">企业基本信息</span> ——————
        </div>
        <a-row>
          <a-col :span="8">
            <a-form-item label="企业名称">
              <a-input
                v-decorator="['companyName', validateCompanyName]"
                placeholder="请输入企业名称"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="企业统一社会信用代码">
              <div class="creditCode">
                <a-input
                  v-decorator="['creditCode', validateCreditCode]"
                  placeholder="请输入企业统一社会信用代码"
                />
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="单位电话">
              <a-input
                v-decorator="['appCompanyPhone', validateAppCompanyPhone]"
                placeholder="请输入单位电话"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="法定代表人">
              <a-input
                v-decorator="['legalName', validateLegalName]"
                placeholder="请输入法定代表人姓名"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="法人证件类型">
              <a-select
                v-decorator="['legalCertType', validateLegalCertType]"
                style="width: 200px"
                placeholder="请选择法人证件类型"
              >
                <a-select-option
                  v-for="item in legalCertTypeList"
                  :key="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="法人证件号">
              <a-input
                v-decorator="['legalCertNo', validateLegalCertNo]"
                placeholder="请输入法人证件号"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="基本户银行账户">
              <a-input
                v-decorator="[
                  'account',
                  {
                    rules: [
                      {
                        validator: compareToFirstPassword,
                      },
                    ],
                  },
                ]"
                placeholder="请输入基本户银行账户"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="基本户开户行名称">
              <a-input
                v-decorator="['bankNo', validateBankNo]"
                placeholder="请输入开户行名称"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="拓展人">
              <a-input
                v-decorator="['extender']"
                placeholder="请输入拓展人"
              />
            </a-form-item>
          </a-col>
          
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="拓展机构">
              <a-input
                v-decorator="['extendAgency']"
                placeholder="请输入拓展机构"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="form-title">
          —————— <span class="txt">开票信息</span> ——————
        </div>
        <a-row>
          <a-col :span="8">
            <a-form-item label="发票类型">
              <a-select
                v-decorator="['invoiceType', validateInvoiceType]"
                style="width: 200px"
                placeholder="请选择发票类型"
              >
                <a-select-option
                  v-for="item in invoiceTypeList"
                  :key="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="纳税人识别号">
              <a-input
                v-decorator="['cuscCode', validateCuscCode]"
                placeholder="请输入纳税人识别号"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="银行账号">
              <a-input
                v-decorator="['bankAcc', validateBankAcc]"
                placeholder="请输入银行账号"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="开户行名称">
              <a-input
                v-decorator="['bankInfo', validateBankInfo]"
                placeholder="请输入开户行名称"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="联系地址">
              <a-input
                v-decorator="['contactAddress', validateContactAddress]"
                placeholder="请输入联系地址"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="电子邮箱">
              <a-input
                v-decorator="['email', validateEmail]"
                placeholder="请输入电子邮箱"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="form-title">
          —————— <span class="txt">经办人信息</span> ——————
        </div>

        <a-row>
          <a-col :span="8">
            <a-form-item label="姓名">
              <a-input
                v-decorator="['principalName', validatePrincipalName]"
                placeholder="请输入经办人姓名"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="身份证号">
              <a-input
                v-decorator="['principalIdCard', validatePrincipalIdCard]"
                placeholder="请输入经办人身份证号"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="手机号">
              <a-input
                v-decorator="['principalPhone', validatePrincipalPhone]"
                placeholder="请输入经办人手机号"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="form-title">
          —————— <span class="txt">附件信息</span> ——————
        </div>

        <a-row>
          <a-col :span="8">
            <a-form-item label="企业营业执照">
              <span class="ant-form-text">
                请提供扫描件。支持jpg、png格式文件，文件不能大于5M
              </span>
              <UploadBtn :list.sync="businessFile" :liNum="1"></UploadBtn>

              <!-- <a-upload
                :action="VUE_APP_FIRST_URL + '/api/contract/upload'"
                :data="{ fileUrl: 'entCertForm' }"
                list-type="picture-card"
                :file-list="businessFile"
                @preview="handlePreview"
                @change="value => handleChange('business', value)"
                :beforeUpload="beforeUpload"
              >
                <div v-if="businessFile.length < 1">
                  <a-icon type="plus" />
                </div>
              </a-upload>
              <a-modal
                :visible="previewVisible"
                :footer="null"
                @cancel="handleCancel"
              >
                <img style="width: 100%" :src="previewImage" />
              </a-modal> -->
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="法人身份证">
              <span class="ant-form-text">
                请提供正反面扫描件。支持jpg、png格式文件，文件不能大于5M
              </span>
              <div style="display: flex">
                <UploadBtn
                  :list.sync="legalFile1"
                  text="身份证正面"
                  :liNum="1"
                ></UploadBtn>

                <!-- <a-upload
                  :action="VUE_APP_FIRST_URL + '/api/contract/upload'"
                  :data="{ fileUrl: 'entCertForm' }"
                  list-type="picture-card"
                  :file-list="legalFile1"
                  @preview="handlePreview"
                  @change="value => handleChange('legal1', value)"
                  :beforeUpload="beforeUpload"
                >
                  <div v-if="legalFile1.length < 1">
                    <a-icon type="plus" />
                    <div class="ant-upload-text">身份证正面</div>
                  </div>
                </a-upload> -->
                <!-- <a-upload
                  :action="VUE_APP_FIRST_URL + '/api/contract/upload'"
                  :data="{ fileUrl: 'entCertForm' }"
                  list-type="picture-card"
                  :file-list="legalFile2"
                  @preview="handlePreview"
                  @change="value => handleChange('legal2', value)"
                  :beforeUpload="beforeUpload"
                >
                  <div v-if="legalFile2.length < 1">
                    <a-icon type="plus" />
                    <div class="ant-upload-text">身份证反面</div>
                  </div>
                </a-upload> -->
                <UploadBtn
                  :list.sync="legalFile2"
                  text="身份证反面"
                  :liNum="1"
                ></UploadBtn>
              </div>
              <!-- <a-modal
                :visible="previewVisible"
                :footer="null"
                @cancel="handleCancel"
              >
                <img style="width: 100%" :src="previewImage" />
              </a-modal>
              <a-modal
                :visible="previewVisible"
                :footer="null"
                @cancel="handleCancel"
              >
                <img style="width: 100%" :src="previewImage" />
              </a-modal> -->
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="最近一期财务报表" class="labes" v-if="isfile">
              <a-upload
                name="file"
                :multiple="true"
                :action="VUE_APP_FIRST_URL + '/api/contract/upload'"
                :data="{ fileUrl: 'entCertForm' }"
                v-decorator="[
                  'filenew',
                  { rules: [{ required: true, message: '请选择' }] },
                ]"
                @change="handleChangelastApplyFiles"
              >
                <a-button
                  class="upload-btn"
                  style="width: 100px; background: #fff"
                >
                  <img
                    style="width: 18px; height: 18px; margin-top: -4px"
                    src="../../assets/image/icon_scwj.png"
                    alt=""
                  />
                  <span style="color: #005bac; padding-left: 4px"
                    >上传文件</span
                  >
                </a-button>
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="8">
            <a-form-item label="企业建筑资质">
              <span class="ant-form-text">
                支持jpg、png格式文件，文件不能大于5M
              </span>
              <UploadBtn :list.sync="imgArr"></UploadBtn>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="开户许可证">
              <span class="ant-form-text">
                支持jpg、png格式文件，文件不能大于5M
              </span>
              <UploadBtn :list.sync="imgArr1"></UploadBtn>
            </a-form-item>
          </a-col>
        </a-row>

        <a-form-item :wrapper-col="{ span: 12, offset: 10 }" class="entBtn">
          <a-button type="primary" html-type="submit">
            确定并进入下一步
          </a-button>
        </a-form-item>
      </a-form>
    </div>

    <a-modal
      title="登录CA证书"
      :visible="visible"
      @ok="handleCA"
      @cancel="handleDown"
      cancel-text="取消"
      ok-text="CA登录"
      :get-container="() => $refs.container"
    >
      <!-- 地址 -->
      <a-spin :spinning="spinAddress">
        <div class="CAcode">
          <div class="CAlogin">
            <a-icon type="exclamation-circle" />
            <div>
              请登录您的CA证书，如为颁发的CA证书，无需进行人工审核；<br />
              如为其他机构颁发的CA证书，人工审核将在1-2个工作日完成
            </div>
          </div>
          <div>
            <span v-if="caFlag === '-1'">
              未检测到您的CA证书，请再次尝试或<a @click="handleDown">点击确认</a
              >进入人工审核
            </span>
            <span v-if="caFlag === '0'">
              检测到您登录为非颁发的证书或证书信息不全，<a @click="handleDown"
                >点击确认</a
              >进入人工审核
            </span>
            <span v-if="caFlag === '1'">
              恭喜您，检测到您登录的为颁发的证书，系统自动审核通过，{{
                time
              }}秒后跳转至账户管理
            </span>
          </div>
        </div>
      </a-spin>
    </a-modal>

    <a-modal
      title="信息认证"
      :visible="visibleMsg"
      @ok="msgOk"
      @cancel="msgDown"
      cancel-text="取消"
      ok-text="确认"
      :get-container="() => $refs.container"
    >
      <!-- 地址 -->
      <a-spin :spinning="spinAddress">
        <div class="CAcode">
          <div class="CAlogin">
            <a-icon type="exclamation-circle" />
            <div>提交信息确认无误后请点击确认</div>
          </div>
        </div>
      </a-spin>
    </a-modal>

    <Modal :visible.sync="visibleIsShow"></Modal>
  </div>
</template>

<script>
import PlateTitle from "@/components/PlateTitle";
import UploadBtn from "@/components/UploadBtn/index";

import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import moment from "moment";
moment.locale("zh-cn");

import { submitApproveInfo } from "@/api/entCertification/entCertification.js";
import { entInfo } from "@/api/entCertification/entCertification.js";
import { checkYSTService } from "@/assets/script/CAjs/caLogin.js";
import { dateFormatDay } from "@/utils/format.js";
import Modal from "@/components/modal.vue";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default {
  name: "EntCertForm",
  components: {
    PlateTitle,
    Modal,
    UploadBtn,
  },
  data() {
    return {
      imgArr: [],
      imgArr1: [],
      visibleIsShow: false,
      certificationFlag: null,
      errMsg: "",
      time: 2,
      timer: "",
      caFlag: "",
      spinAddress: false,
      visible: false,
      visibleMsg: false,
      locale,
      form: this.$form.createForm(this, { name: "coordinated" }),
      previewVisible: false,
      identifyDisabled: false,
      defaultLongTime: false,
      previewImage: "",
      file: {},
      basicFile: [],
      businessFile: [], // 企业营业执照
      legalFile1: [], // 法人身份证正面照
      legalFile2: [], // 法人身份证反面照
      companyInfo: {
        caFlag: "",
        companyName: "",
        creditCode: "",
      },

      legalCertTypeList: [
        // 法人证件类型列表
        { label: "身份证", value: "1" },
        { label: "统一社会信用代码", value: "2" },
        { label: "护照", value: "3" },
      ],
      invoiceTypeList: [
        // 发票类型列表
        { label: "纸质（普票）", value: "1" },
        { label: "电子（普票）", value: "2" },
        { label: "纸质（专票）", value: "3" },
      ],
      validateCompanyName: {
        rules: [
          { type: "string", required: true, message: "请输入投标企业名称" },
        ],
      },
      validateCreditCode: {
        rules: [
          {
            type: "string",
            required: true,
            message: "请输入投标企业统一社会信用代码",
          },
        ],
      },
      validateEstablishDate: {
        rules: [{ required: true, message: "请选择成立日期" }],
      },
      validateLegalName: {
        rules: [
          { type: "string", required: true, message: "请输入法定代表人姓名" },
        ],
      },
      validateLegalCertType: {
        rules: [
          { type: "string", required: true, message: "请选择法人证件类型" },
        ],
      },
      validateLegalCertNo: {
        rules: [
          { type: "string", required: true, message: "请输入法人证件号" },
        ],
      },
      validateRegisteredCapital: {
        rules: [
          { type: "string", required: true, message: "请输入注册资本" },
          {},
        ],
      },
      validateAppCompanyAddress: {
        rules: [{ type: "string", required: true, message: "请输入企业地址" }],
      },
      validateAccount: {
        rules: [
          { type: "string", required: true, message: "请输入基本户银行账户" },
        ],
      },
      validateBankNo: {
        rules: [
          { type: "string", required: true, message: "请输入基本户开户行名称" },
        ],
      },
      validateAppCompanyPhone: {
        rules: [{ type: "string", required: true, message: "请输入公司电话" }],
      },
      validateIdentifyStart: {
        rules: [{ required: true, message: "请选择营业执照有效开始日期" }],
      },
      validateInvoiceType: {
        rules: [{ type: "string", required: true, message: "请选择发票类型" }],
      },
      validateCuscCode: {
        rules: [
          { type: "string", required: true, message: "请输入纳税人识别号" },
        ],
      },
      validateBankAcc: {
        rules: [{ type: "string", required: true, message: "请输入银行账号" }],
      },
      validateBankInfo: {
        rules: [
          { type: "string", required: true, message: "请输入开户行名称" },
        ],
      },
      validateContactAddress: {
        rules: [{ type: "string", required: true, message: "请输入联系地址" }],
      },
      validateMailAddress: {
        rules: [{ type: "string", required: true, message: "请输入邮寄地址" }],
      },
      validateEmail: {
        rules: [{ type: "string", required: true, message: "请输入电子邮箱" }],
      },
      validatePhoneNo: {
        rules: [{ type: "string", required: true, message: "请输入联系电话" }],
      },
      validatePrincipalName: {
        rules: [
          { type: "string", required: true, message: "请输入经办人姓名" },
        ],
      },
      validatePrincipalIdCard: {
        rules: [
          { type: "string", required: true, message: "请输入经办人身份证号" },
        ],
      },
      validatePrincipalPhone: {
        rules: [
          { type: "string", required: true, message: "请输入经办人手机号" },
        ],
      },
      VUE_APP_FIRST_URL: "",
      dataList: {},
      fileImg: [],
      isfile: false,
      lastApplyId: null,
      lastFinalStatus: null,
    };
  },
  mounted() {
    this.VUE_APP_FIRST_URL = process.env.VUE_APP_FIRST_URL;
    this.initData();
    if (this.$route.query && this.$route.query.companyInfo) {
      const companyInfo = JSON.parse(
        decodeURIComponent(this.$route.query.companyInfo)
      );
      this.companyInfo = { ...companyInfo };
      this.caFlag = this.companyInfo.caFlag;
      this.form.setFieldsValue({
        companyName: this.companyInfo.companyName
          ? this.companyInfo.companyName
          : "",
        creditCode: this.companyInfo.certCode ? this.companyInfo.certCode : "",
      });
      if (this.companyInfo.caFlag === "1") {
        this.timer = setInterval(this.countDown, 1000);
      }
    }
  },
  methods: {
    goBack() {
      // 认证成功
      if (this.certificationFlag == 1) {
        this.$router.replace({ path: "/orderCenter" });
      } else {
        this.visibleIsShow = true;
      }
    },
    handleChangelastApplyFiles(info) {
      if (info.file.status === "done") {
        if (info.fileList && info.fileList.length > 0) {
          this.fileImg = info.fileList.map((item) => {
            return { fileName: item.name, filePath: item.response.data.url };
          });
        }
      }
    },
    validateIdentifyExpiry(rule, value, callback) {
      if (!this.identifyDisabled) {
        if (!value) {
          this.$message.warning("请选择营业执照有效到期日期");
          callback("请选择营业执照有效到期日期");
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
    // 初始化页面
    initData() {
      entInfo().then((res) => {
        const data = res.data.data;
        // 企业是否认证
        this.certificationFlag = res.data.data.certificationFlag;
        const dataList = JSON.stringify(res.data.data);
        this.dataList = JSON.parse(dataList);
        if (!data.establishDate) {
          delete data.establishDate;
        }
        if (data.identifyStart === null) {
          delete data.identifyStart;
        }
        if (data.identifyExpiry === null) {
          delete data.identifyExpiry;
        }
        if (data.identifyExpiry === 4102358400000) {
          this.identifyDisabled = true;
          this.defaultLongTime = true;
        }

        this.form.setFieldsValue({
          companyName: data.companyName, // 投标企业名称
          creditCode: data.creditCode, // 投标企业统一社会信用代码
          establishDate: data.establishDate
            ? dateFormatDay(data.establishDate)
            : null, // 成立日期
          legalName: data.legalName, // 法定代表人
          legalCertType: data.legalCertType ? data.legalCertType : "1", // 法人证件类型
          legalCertNo: data.legalCertNo, // 法人证件号
          registeredCapital: data.registeredCapital
            ? data.registeredCapital.toString()
            : data.registeredCapital, // 注册资本
          appCompanyAddress: data.appCompanyAddress, // 企业地址
          account: data.account, // 基本户银行账户
          bankNo: data.bankNo, // 基本户开户行名称
          appCompanyPhone: data.appCompanyPhone, // 公司电话
          identifyStart: data.identifyStart
            ? dateFormatDay(data.identifyStart)
            : null, // 营业执照有效开始日期
          identifyExpiry: data.identifyExpiry
            ? dateFormatDay(data.identifyExpiry)
            : null, // 营业执照有效到期日期
          invoiceType: data.invoiceType ? data.invoiceType : "1", // 发票类型：1 纸质（普票）2  电子（普票）3 纸质（专票）
          cuscCode: data.cuscCode, // 纳税人识别号
          bankAcc: data.bankAcc, // 银行账号
          bankInfo: data.bankInfo, // 开户行名称
          contactAddress: data.contactAddress, // 联系地址
          mailAddress: data.mailAddress, // 邮寄地址
          email: data.email, // 电子邮箱
          phoneNo: data.phoneNo, // 联系电话
          principalName: data.principalName, // 经办人姓名
          principalIdCard: data.principalIdCard, // 经办人身份证号
          principalPhone: data.principalPhone, // 经办人手机号
          lastFinalStatus: data.lastFinalStatus, // 是否显示附件
          applyResultStr: data.applyResultStr, // 提示信息
          lastApplyId: data.lastApplyId,
          extendAgency: data.extendAgency,
          extender: data.extender,

        });
        this.errMsg = data.applyResultStr;
        this.isfile = data.lastFinalStatus == "40" ? true : false;
        this.lastApplyId = data.lastApplyId;
        this.lastFinalStatus = data.lastFinalStatus;

        let arr = JSON.parse(res.data.data.qualificationImage);
        let arr1 = JSON.parse(res.data.data.accountLicenceImage);

        this.imgArr = arr.map((item, index) => {
          return {
            uid: index,
            name: "image.png",
            status: "done",
            url: this.VUE_APP_FIRST_URL + item.filePath,
          };
        });

        this.imgArr1 = arr1.map((item, index) => {
          return {
            uid: index,
            name: "image.png",
            status: "done",
            url: this.VUE_APP_FIRST_URL + item.filePath,
          };
        });

        console.log("--==", this.imgArr);

        if (data.legalPersonCardImage !== null) {
          this.file = {
            businessFile: data.businessLicenseImage,
            legalFile: data.legalPersonCardImage.split(","),
          };
          try {
            this.businessFile = [
              {
                uid: "001",
                name: "image.png",
                status: "done",
                url:
                  this.VUE_APP_FIRST_URL +
                  JSON.parse(data.businessLicenseImage)[0].filePath,
              },
            ];
          } catch {}

          try {
            this.legalFile1 = [
              {
                uid: "002",
                name: "image.png",
                status: "done",
                url:
                  this.VUE_APP_FIRST_URL +
                  JSON.parse(data.legalPersonCardImage)[0].filePath,
              },
            ];
          } catch {}
          try {
            this.legalFile2 = [
              {
                uid: "003",
                name: "image.png",
                status: "done",
                url:
                  this.VUE_APP_FIRST_URL +
                  JSON.parse(data.legalPersonCardImage)[1].filePath,
              },
            ];
          } catch {}
        }
      });
    },
    handleCA() {
      checkYSTService("company");
      this.timer = "";
    },
    countDown() {
      if (this.time > 0) {
        this.time--;
      } else {
        this.time = 0;
        this.visible = false;
        clearInterval(this.timer);
        this.$router.push({ name: "user" });
      }
    },
    handleOk() {},
    handleDown() {
      this.visible = false;
    },
    longTime(e) {
      this.defaultLongTime = !this.defaultLongTime;
      if (e.target.checked) {
        this.identifyDisabled = true;
      } else {
        this.identifyDisabled = false;
      }
    },
    // 下一步
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (err) {
          return this.$message.warning("基本信息未填写完全！");
        }
        if (this.imgArr.length == 0) {
          this.$message.warning("请上传企业建筑资质");
          return false;
        }
        if (this.imgArr1.length == 0) {
          this.$message.warning("请上传开户许可证");
          return false;
        }
        if (this.businessFile.length === 0) {
          this.$message.warning("请上传营业执照");
          return false;
        }
        if (this.legalFile1.length === 0 || this.legalFile2.length === 0) {
          this.$message.warning("请上传法人身份证");
          return false;
        }

        this.visibleMsg = true;
      });
    },
    msgOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          const info = this.$store.state.info;
          this.businessFile.forEach((item) => {
            if (item.response) {
              this.file.businessFile = item.response.data.url;
            }
          });
          if (!this.file.legalFile) {
            this.file.legalFile = [];
          }
          if (this.legalFile1[0].response) {
            this.file.legalFile[0] = this.legalFile1[0].response.data.url;
          }
          if (this.legalFile2[0].response) {
            this.file.legalFile[1] = this.legalFile2[0].response.data.url;
          }
          let legal = "";
          const legalPersonCard = [];
          const str = this.file.legalFile[0];
          if (str[0] === "[") {
            legal = this.dataList.legalPersonCardImage;
          } else {
            const files = { filePath: this.file.legalFile[0], key: 1 };
            const filed = { filePath: this.file.legalFile[1], key: 2 };
            legalPersonCard.push(files);
            legalPersonCard.push(filed);
          }
          const params = {
            ...values,
            customerId: info.id,
            // establishDate: dateFormatDay(values.establishDate),
            // identifyStart: dateFormatDay(values.identifyStart),
            // identifyExpiry: dateFormatDay(values.identifyExpiry),
            businessLicenseImage: this.file.businessFile,
            legalPersonCardImage:
              str[0] === "[" ? legal : JSON.stringify(legalPersonCard),
            certificationFlag: info.certificationFlag,
          };

          let arr = this.imgArr.map((item) => {
            return {
              filePath: item.response ? item.response.data.url : item.url,
            };
          });
          let arr1 = this.imgArr1.map((item) => {
            return {
              filePath: item.response ? item.response.data.url : item.url,
            };
          });

          params.qualificationImage = JSON.stringify(arr);
          params.accountLicenceImage = JSON.stringify(arr1);

          if (this.identifyDisabled) {
            params.identifyExpiry = "2099-12-31";
          }
          if (params.businessLicenseImage[0] === "[") {
            params.businessLicenseImage = this.dataList.businessLicenseImage;
          } else {
            const businessLicenseImage = params.businessLicenseImage;
            const businessd = [];
            businessd.push({ filePath: businessLicenseImage });
            params.businessLicenseImage = JSON.stringify(businessd);
          }
          params.registeredCapital = Number.parseFloat(
            params.registeredCapital
          );
          params.lastApplyId = this.lastApplyId;
          params.lastFinalStatus = this.lastFinalStatus;
          params.lastApplyFiles = this.isfile
            ? JSON.stringify(this.fileImg)
            : null;
          this.$delete(params, "filenew");
          console.log("-=-==", params);
          submitApproveInfo(params).then((res) => {
            this.visibleMsg = true;
            if (res.data.status === "FAIL") {
              this.$message.warning(res.data.errorMsg);
            } else {
              this.$message.success("企业认证提交成功");
              this.$router.push({ name: "entCertification" });
            }
          });
        }
      });
    },
    msgDown() {
      this.visibleMsg = false;
    },
    beforeUpload(file, fileList) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error(`上传文件大小不能超过5 MB,请将图片删除!`);
      }

      // return isLt5M;
    },
    // 取消预览
    handleCancel() {
      this.previewVisible = false;
    },
    // 预览
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    // 上传
    handleChange(type, { fileList }) {
      console.log("--==", fileList);
      if (type === "business") {
        if (fileList.length > this.businessFile.length) {
          if (
            !(
              fileList[fileList.length - 1].type === "image/jpeg" ||
              fileList[fileList.length - 1].type === "image/png"
            )
          ) {
            this.$message.warning("请上传正确格式的文件。");
            return false;
          }
        }
        this.businessFile = fileList;
      } else if (type === "legal1") {
        if (fileList.length > this.legalFile1.length) {
          if (
            !(
              fileList[fileList.length - 1].type === "image/jpeg" ||
              fileList[fileList.length - 1].type === "image/png"
            )
          ) {
            this.$message.warning("请上传正确格式的文件。");
            return false;
          }
        }
        this.legalFile1 = fileList;
      } else if (type === "legal2") {
        if (fileList.length > this.legalFile2.length) {
          if (
            !(
              fileList[fileList.length - 1].type === "image/jpeg" ||
              fileList[fileList.length - 1].type === "image/png"
            )
          ) {
            this.$message.warning("请上传正确格式的文件。");
            return false;
          }
        }
        this.legalFile2 = fileList;
      }
    },
    compareToFirstPassword(rule, value, callback) {
      const regex = /^\d+$/;
      const isValid = regex.test(value);
      if (!isValid) {
        callback("基本户银行账户必须是数字格式且不能有空格！");
      } else {
        callback();
      }
    },
  },
};
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

.ant-upload-select-picture-card i {
  color: #999;
  font-size: 32px;
}

.ant-upload-select-picture-card {
  margin-top: 8px;
  color: #666;
}

.ant-upload-picture-card-wrapper {
  width: 125px;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 700px;
  margin: 30px auto;
  background-color: #fff;

  .ant-form-text {
    display: block;

    &::before {
      display: inline-block;
      content: "*";
      line-height: 1;
      margin-right: 4px;
      color: #f5222d;
      font-size: 14px;
      font-family: SimSun, sans-serif;
    }
  }

  /deep/ .ant-modal-header {
    border: none;
  }

  /deep/ .ant-modal-footer {
    height: 76px;
    border: none;
    background-color: #f7faff;
  }

  /deep/ .ant-modal-body {
    padding: 0 24px;
  }

  /deep/ .ant-spin-container {
    padding-top: 40px;
    padding-bottom: 64px;
    border-top: 1px solid #ebf2f7;
  }

  /deep/ .ant-btn {
    width: 100px;
    height: 36px;
    margin-top: 10px;
    color: #007ce2;
    font-size: 14px;
    font-weight: 400;
    font-family: Microsoft YaHei;
    border: 1px solid #007ce2;
    border-radius: 3px;
  }

  /deep/ .ant-btn-primary {
    margin-right: 14px;
    margin-left: 20px;
    color: #fff;
    background: #007ce2;
  }
}

.wain {
  position: relative;
  width: 1100px;
  height: 70px;
  margin: 30px auto 0 auto;
  background: #fffbf3;

  .wainIcon {
    position: absolute;
    top: 16px;
    left: 30px;
    width: 12px;
    height: 12px;
    color: #e7b24b;
  }

  p {
    margin: 12px 12px 12px 53px;
    color: #e7b24b;
    font-size: 14px;
    font-weight: 400;
    font-family: Microsoft YaHei;
  }
}

.main {
  width: 1100px;
  margin: auto;
  padding: 40px 0px 20px;
}

.form {
  margin-top: 20px;

  .form-title {
    color: #ebebeb;
    text-align: center;

    .txt {
      color: #333;
      font-size: 16px;
    }
  }

  /deep/ .ant-alert-warning {
    width: 500px;
    margin: 0 auto;
    padding: 8px 8px 8px 35px;
    color: #999;

    span {
      color: #666;
    }
  }

  /deep/ .ant-upload {
    width: 103px;
    height: 103px;
  }

  /deep/ .ant-form-item {
    margin: 20px 0;

    span {
      color: #999;
      font-size: 14px;
      font-weight: 400;
      font-family: Microsoft YaHei;
    }

    .creditCode {
      display: flex;
      flex-direction: column;
    }

    .same {
      line-height: 14px;
      margin-top: 7px;
    }
  }

  /deep/ .ant-form-item label {
    margin-right: 10px;
    color: #666;
    font-size: 14px;
    font-weight: 400;
    font-family: Microsoft YaHei;
  }

  .identify {
    span {
      margin: 0 3px;
    }

    /deep/ .ant-input {
      width: 150px;
    }
  }

  /deep/ .ant-input {
    width: 200px;
    height: 36px;
    padding-left: 8px;
    border: 1px solid #e1e8ef;
    border-radius: 3px;
    background: #ffffff;
  }

  /deep/ .ant-btn {
    width: 200px;
    height: 44px;
    margin: 40px 0 60px 0;
    border-radius: 4px;
    background: #007ce2;

    span {
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      font-family: Microsoft YaHei;
    }
  }

  .entBtn {
    width: 1080px;
    margin: 0 auto;
    border-top: 1px solid #ebf2f7;
  }
}

.CAcode {
  .CAlogin {
    position: relative;
    display: flex;
    margin: 0 auto;
    background: #fffbf3;

    .anticon {
      position: absolute;
      top: 14px;
      left: 10px;
      width: 14px;
      height: 14px;
      color: #e7b24b;
    }

    div {
      padding: 10px 0 9px 33px;
      color: #e7b24b;
      font-size: 14px;
      font-weight: 400;
      font-family: Microsoft YaHei;
    }
  }

  /deep/ .ant-input {
    width: 330px;
    height: 36px;
    margin: 15px 0;
    padding-left: 8px;
    border: 1px solid #e1e8ef;
    border-radius: 3px;
    background: #ffffff;
  }
}

.labes {
  display: flex;
  flex-wrap: wrap;
  /deep/ .ant-form-item-label {
    width: 100%;
    padding-left: 20px;
    text-align: left;
  }
  /deep/ .ant-form-item-control-wrapper {
    padding-left: 20px;
  }
}
</style>
